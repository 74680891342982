body {
  background-color: rgb(92, 92, 92);
}

a{
  color:#8fab3b;
  font-weight: 800;
}

a:hover{
  color:#3d7b3e;
}

.frontDoor{
  text-align: center;
  justify-content: center;
  transform: translateY(20vh);
  -webkit-transform: translateY(20vh);
  -ms-transform: translateY(20vh);
  -moz-transform: translateY(20vh);
  -o-transform: translateY(20vh);
}

.frontDoor img{
  width: 25%;
}

.open{
  display: block;
  position: absolute;
  min-width: 100vw;
  min-height: 100vh;
  cursor: pointer;
}

.introText{
  font-family: 'Work Sans', sans-serif;
  font-size: 40px;
  color:#a3c441;
  text-shadow: 6px 6px  30px #102010;
  text-align: center;
  display: block;
  justify-content: center;
  transform: translateY(30vh);
  -webkit-transform: translateY(30vh);
  -ms-transform: translateY(30vh);
  -moz-transform: translateY(30vh);
  -o-transform: translateY(30vh);
}
.siteBody{
  font-family: 'Work Sans', sans-serif;
  font-size: 25px;
  color: #333;
  display: block;
  min-height: 100vh;
}

.siteBodyWhite{
  background-color: #effcc8;
  font-family: 'Work Sans', sans-serif;
  font-size: 25px;
  color: #333;
  display: block;
  min-height: 100vh;
  padding-left: 5em;
  padding-right: 5rem;
}

.cardGrey{
  display: flex;
  flex-direction: column;
  color: #e5f8ac;
  margin-right: 20vw;
  text-align: right;
}
.cardWhite{
  display: flex;
  flex-direction: column;
  margin-right: 20vw;
  text-align: right;
}

.cardGrey h3, .cardWhite h3{
  padding-top: 7vh;
  margin-bottom: 0;
  font-size: 40px;
}

.cardContainer{
  padding-top: 5vh;
  padding-bottom: 5vh;
  margin-left: 2rem;
  margin-right: 2rem;
  display:flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.nav{
  position: fixed;
  left: 0px;
  top: 40%;
}

.nav a {
  border: 1px solid #8fab3b;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  display: block;
  margin: 10px;
  margin-top: 4vh;
  cursor: pointer;
}

.nav a.active, .nav a:hover{
  background: #8fab3b;
}

.main{
  height: 100vh;
  color: #e5f8ac;
  margin-left: 20vw;
  text-align: left;
  display: flex;
  flex-direction: row-reverse;
}

.main h3 {
  padding-top: 40vh;
  margin-bottom: 0;
  font-size: 40px;
}
.main p {
  margin-top: 0;
}

.profile {
  margin-top: 36vh;
  margin-left: 15vw;
  margin-right: 15vw;
  object-fit: cover;
  width: 30vh;
  height: 30vh;
  border-radius: 50%;
}

.techstackDiv{
  margin-right: 10vw;
  margin-left: 10vw;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 5vh;
}
.techstackColumn{
  width: 40%;
  min-height: fit-content;
  border-radius: 7px;
  padding-right:3vw;
  padding-left: 3vw;
  padding-top: 3vh;
  padding-bottom: 3vh;
}

.techstackColumn h3{
  text-align: center;
  color: #e5f8ac;
}

.progress-label{
  width:100%;
  font-size: 16px;
  color:#e5f8ac;
  margin-top: 2vh;
}
.progress-bar{
  display: block;
  height: 100%;
  background-color: #8fab3b;
  color: #333;
  font-weight: 600;
  line-height: 2rem;
  text-align: left;
  text-indent: 1rem;
  border-radius: .25rem;
  position: relative;
  overflow: hidden;
}

.progress{
  position: relative;
  background: #333;
  border-radius: .25rem;
  height: 2rem;
  font-size: 15px;
}

.card{
  width: 30%;
  margin-bottom: 2rem;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: .25rem;
}
.card img{
  width: 100%;
  height: 15rem;
  object-fit: cover;
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px);
}
.card-body{
  padding: 1.25rem;
  height: 15rem;
}

.projectCardTitle{
  font-size: 25px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: .75rem;
}
.projectCardName{
  font-size: 18px;
  margin-bottom: 1rem;
}
.projectCardButtonCenter{
  position: absolute;
  width:calc(100% - 2.5rem);
  text-align: center;
  bottom: 1.25rem;
}
.projectCardButton{
  background-color: #8fab3b;
  color: #f8f8ff;
  text-align: center;
  border:none;
  display:inline-block;
  cursor: pointer;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.card-body a{
  text-decoration: none!important;
}
.card-body a:hover{
  color: #f8f8ff;
}
.projectCardButton:hover{
  background-color: #3d7b3e;
}

.contactText{
  color:#e5f8ac;
  text-align: center;
  padding: 3vh;
}

.socialMediaContainer{
  display:flex;
  flex-wrap: wrap;
  justify-content:space-between;
  padding: 10vh;
  max-width: 500px;
  margin: 0 auto;
}
.socialMediaContainer img{
  object-fit: cover;
  width: 10vh;
  height: 10vh;
}


@media only screen and (max-width: 765px) {
  .frontDoor img{
    width: 75%;
  }
  .cardGrey{
    text-align: center;
  }
  .cardWhite{
    text-align: center;
  }

  .cardGrey h3, .cardWhite h3{
    text-align: right;
  }
  .main{
    text-align: right;
    flex-direction: column;
    align-items: center;
    margin-right: 6vw;
  }
  .main h3{
    padding-top: 10vh;
    font-size:35px;
  }
  .profile{
    margin-top:10vh;
  }
  .nav ul{
    padding-inline-start: 2vw;
  }
  .cardBody{
    font-size: 18px;
  }
  .techstackDiv{
    flex-direction: column;
  }
  .techstackColumn{
    width:80vw;
  }

  .card{
    width:100%;
  }
  
  .siteBodyWhite{
    padding: 0px 1rem 1rem 1rem;
  }

  .socialMediaContainer{
    padding: 10vw 5vw;
    justify-content: center;
  }
  .socialMediaContainer img{
    margin:.6rem;
  }

}